import React from "react"
import { CategorySlider } from "@common"

const TherapiesTopSlider = ({ categories }) => {
  const _cat = categories.filter(el => el.count)
  return (
    <div>
      <CategorySlider data={_cat} />
    </div>
  )
}

export default TherapiesTopSlider
