import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Parallax } from "@common"

const AttentionSection = () => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(uri: "home") {
          ACF_attention {
            attention1 {
              title
              title1
              title2
              backgroundImage {
                sourceUrl
                srcSet
                altText
              }
            }
          }
        }
      }
    }
  `)

  const { attention1 } = WP.pageBy.ACF_attention
  const { title1, title2, title } = attention1

  return (
    <section className="attention">
      <Parallax image={attention1.backgroundImage}>
        <div className="container">
          <div className="row justify-content">
            <div className="col-md-7 col-xs-12">
              <h1 dangerouslySetInnerHTML={{ __html: title1 }}></h1>
              <h4 dangerouslySetInnerHTML={{ __html: title2 }}></h4>
              <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
              <div className="decoration"></div>
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  )
}

export default AttentionSection
