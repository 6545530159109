import React from "react"
import { Link } from "gatsby"
import { ResizedImage } from "@common"

const Prices = ({ regularPrice, salePrice }) => {
  if (!salePrice) {
    return (
      <h6>
        <span dangerouslySetInnerHTML={{ __html: regularPrice }} />
      </h6>
    )
  } else {
    return (
      <h6>
        <small dangerouslySetInnerHTML={{ __html: regularPrice }} />
        <span dangerouslySetInnerHTML={{ __html: salePrice }} />
      </h6>
    )
  }
}

const SingleProduct = ({ name, slug, regularPrice, salePrice, image }) => {
  return (
    <div className="products-item">
      <Link to={`/products/${slug}/`}>
        <div className="products-image">
          <ResizedImage {...image} size={"300w"} />
          <div className="products-inner">
            <h5 className="products-title">Zobacz</h5>
          </div>
        </div>
        <div className="products-description">
          <h5 className="products-title">{name}</h5>
          <div className="products-price">
            <Prices salePrice={salePrice} regularPrice={regularPrice} />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default SingleProduct
