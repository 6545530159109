import React from "react"
import { TherapySlider, TherapySliderMobile } from "@common"

const TherapiesSection = ({
  title = "Naturalne wsparcie w uzdrowieniu Twoich dolegliwości",
  categories,
}) => {
  return (
    <section className="therapy">
      <div className="container">
        <div className="row justify-content-center">
          <h1>{title}</h1>
        </div>
        <div className="row">
          <div className="col d-none d-md-block">
            <TherapySlider data={categories} />
          </div>
          <div className="col d-md-none">
            <TherapySliderMobile data={categories} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default TherapiesSection
