import React, { useEffect, useRef } from "react"
import { Head, Layout, Modal, TextInput, EmailPopUp } from "@common"
import { AttentionSection, TherapiesSection } from "@pages"
import { BlogSection, CallTherapistSection, NewsletterSection } from "@sections"
import { WPApi } from "@api"

const IndexPage = ({ pageContext, location }) => {
  let coupon = null
  if (location.search.includes("coupon") || location.search.includes("ref")) {
    coupon = location.search.split("=")[1]
  }
  if (coupon != null) {
    localStorage.setItem("urlCoupon", coupon)
    window.history.replaceState(null, null, "/")
  }
  console.log(pageContext.page)

  return (
    <Layout animation={false}>
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
      />
      <AttentionSection />
      <TherapiesSection categories={pageContext.productCategories} />
      <CallTherapistSection />
      <BlogSection posts={pageContext.posts} />
      <NewsletterSection />
    </Layout>
  )
}
export default IndexPage
